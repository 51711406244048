import { useState, useEffect } from "react";
import { MondayApiQueryInstance } from "../../services/monday/monday-service-ts";
import { getUsersBackend } from "../../utils/requests/getUsersBackend";
import useTokenHook from "../context/useTokenHook";

interface User {
  id: number;
  name: string;
  email: string;
  status: boolean;
}

const useFetchUserStatus = (pageSize = 10, searchTerm = "") => {
  const { token } = useTokenHook();
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const fetchUsers = async (currentPage: number, searchTerm: string) => {
    setIsLoading(true);
    setError(false);
    try {
      const response: any = await MondayApiQueryInstance.getUsers(
        pageSize,
        currentPage,
        searchTerm
      );

      const fetchedUsers = response || [];

      // If no users are returned, we're at the end
      if (fetchedUsers.length === 0) {
        setHasMore(false);
        return;
      }

      const userIds = fetchedUsers.map((user: any) => user.id);
      const backendResponse: any = await getUsersBackend(token, userIds);

      const updatedUsers: User[] = fetchedUsers.map((user: any) => ({
        ...user,
        status: backendResponse.data.data.some(
          (dbUser: any) => dbUser.userid === user.id
        ),
      }));

      // Append fetched users
      setUsers(updatedUsers);

      // Update total pages dynamically only if currentPage exceeds known totalPages
      if (currentPage > totalPages) {
        setTotalPages(currentPage);
      }

      if (searchTerm !== "") {
        setTotalPages(1);
      }

      // Check if there could be more data
      if (fetchedUsers.length === pageSize) {
        setTotalPages(currentPage + 1);
        setHasMore(true); // Potential next page exists
      } else {
        setHasMore(false); // No more data to fetch
      }
    } catch (err: any) {
      setError(true);
      setHasMore(false); // Assume no more data if there's an error
      console.error("Error fetching authorized users:", err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPage = async (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    if (token) {
      fetchUsers(page, searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, token, searchTerm]);

  return {
    users,
    isLoading,
    error,
    fetchPage,
    page,
    hasMore,
    totalPages,
    fetchUsers,
  };
};

export default useFetchUserStatus;
