import simbApi from ".";
import { GET_AUTHORIZED_USERS } from "../../data/constants/endpoints";

export const getUsersBackend = async (
  access_token: string,
  userIds: string[]
) => {
  try {
    const response = await simbApi.post(
      GET_AUTHORIZED_USERS,
      { userIds: userIds },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );

    if (response?.data?.error) {
      throw new Error(response?.data?.error);
    }

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
