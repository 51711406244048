import React from "react";
import { ColumnValueRendererProps } from "../table.data.interface";

const NumbersColumn = ({ columnData }: ColumnValueRendererProps) => {
  if (columnData.value === "Empty Column" || !columnData?.value) {
    return null;
  }
  return <div>{columnData?.value as number}</div>;
};

export default NumbersColumn;
