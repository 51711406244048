import { Chips, Skeleton, Tooltip } from "monday-ui-react-core";
import { Board, Share } from "monday-ui-react-core/icons";
import React, { Fragment } from "react";
import { TableDataInterface } from "./table.data.interface";
import { getColumnIcon } from "../../utils/columnIcons";
import ColumnValueRenderer from "./ColumnValueRenderer";
import MondaySDK from "monday-sdk-js";
import { useSlug } from "../../hooks/context/useSlug";
import { generateItemUrl } from "../../utils/mondayLinks";

interface itemViewColValProps {
  tableData: TableDataInterface | null;
  isLoading: boolean;
  userId: number;
}

const monday = MondaySDK();

const ItemViewColumnValues = ({
  tableData,
  isLoading,
  userId,
}: itemViewColValProps) => {
  const { slug } = useSlug();

  const handleOpenItem = (targetBoardId: string, targetItemId: string) => {
    try {
      const url = generateItemUrl(slug, targetBoardId, targetItemId);
      window.open(url, "_blank", "noopener,noreferrer");
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveItem = async (
    userId: string,
    targetItemId: string,
    targetBoardId: string,
    targetBoardName: string
  ): Promise<void> => {
    try {
      monday
        .execute("openAppFeatureModal", {
          urlPath: "/remove-modal",
          urlParams: {
            userId: userId as string,
            targetItemId: targetItemId as string,
            targetBoardId: targetBoardId as string,
            boardName: targetBoardName as string,
          },
          height: "240px",
          width: "500px",
        })
        .then((res) => {});
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="max-w-[640px] mx-auto ">
      {/* Boards */}
      <div className="grid grid-cols-item-view-values-cols gap-x-[2px]">
        <div className="grid grid-cols-[auto_1fr] items-start gap-[5px] min-h-[36px] p-2">
          <div className="flex gap-[5px] items-center">
            {isLoading ? (
              <>
                <Skeleton type={Skeleton.types.CIRCLE} width={16} height={16} />
                <Skeleton
                  type={Skeleton.types.TEXT}
                  size={Skeleton.sizes.TEXT.P}
                  height={24}
                />
              </>
            ) : (
              <>
                <span className="flex items-center">
                  <Board size={16} color="#676879" />
                </span>
                <p className="!font-figtree text-sm text-light-secondary-text-color">
                  Boards
                </p>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-x-[10px] gap-y-[5px] items-center py-2">
          {isLoading ? (
            <Skeleton
              height={24}
              wrapperClassName="!w-full"
              className="!w-full"
            />
          ) : (
            tableData?.linkedBoards &&
            tableData?.linkedBoards
              ?.filter((board: any) => board?.boardName)
              .slice() // Create a shallow copy to avoid mutating the original array
              .sort((a, b) =>
                (a?.boardName ?? "").localeCompare(b?.boardName ?? "")
              ) // Sort alphabetically by boardName
              .map((data, index) => (
                <Chips
                  className="!m-0"
                  key={index + 1}
                  label={data.boardName}
                  onDelete={() => {
                    handleRemoveItem(
                      userId.toString(),
                      data.itemId,
                      data.boardId,
                      data.boardName
                    );
                  }}
                  onClick={() => {
                    handleOpenItem(data.boardId, data.itemId);
                  }}
                />
              ))
          )}
        </div>
        {/* Column Values */}
        {isLoading
          ? // Skeleton for column values
            Array.from({ length: 5 }).map((_, index) => (
              <Fragment key={index + 1}>
                <div className="grid grid-cols-[auto_1fr] items-center gap-[5px] min-h-[36px] p-2 ">
                  <span className="flex items-center">
                    <Skeleton
                      type={Skeleton.types.CIRCLE}
                      width={16}
                      height={16}
                    />
                  </span>
                  <div className="flex gap-[5px] items-center">
                    <Skeleton
                      type={Skeleton.types.TEXT}
                      size={Skeleton.sizes.TEXT.P}
                      height={24}
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <Skeleton
                    wrapperClassName="!w-full"
                    className="!w-full"
                    height={24}
                  />
                </div>
              </Fragment>
            ))
          : tableData &&
            tableData?.columns?.map((column, index) =>
              column.boards.length > 1 ? (
                <Fragment key={index + 1}>
                  <div className="flex items-start">
                    <div className="grid grid-cols-[auto_1fr] items-center gap-[5px] min-h-[36px] p-2 ">
                      {/* Column Header */}
                      <span className="flex items-center">
                        {getColumnIcon(column.type)}
                      </span>
                      <div className="flex gap-[5px] items-center">
                        <Tooltip content={column.title}>
                          <p className="!font-figtree text-sm text-light-secondary-text-color overflow-hidden whitespace-nowrap !text-ellipsis max-w-[15ch]">
                            {column.title}
                          </p>
                        </Tooltip>
                        {column.boards.length > 1 && (
                          <Tooltip
                            title="Shared in other boards"
                            content={
                              <div className="grid grid-cols-2 gap-x-[10px] gap-y-[5px] mt-1">
                                {column.boards
                                  ?.filter((board: any) => board?.boardName) // Filter out boards with undefined or null boardName
                                  .slice() // Create a shallow copy to avoid mutating the original array
                                  .sort((a: any, b: any) =>
                                    a.boardName.localeCompare(b.boardName)
                                  ) // Sort alphabetically by boardName
                                  .map((board: any, index) => (
                                    <div
                                      key={index + 1} // Ensure each element has a unique key, assuming each board has a unique id
                                      className="text-xs max-w-[150px] text-white !font-figtree font-semibold py-1 px-3 flex items-center justify-center bg-[#EFF6FD26] rounded overflow-hidden"
                                    >
                                      <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                                        {board.boardName}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                            }
                          >
                            <span className="flex items-center transition-opacity opacity-50 hover:opacity-100">
                              <Share size={16} color="#676879" />
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Display Column Value */}
                  <div className="flex items-center !font-figtree text-sm">
                    <ColumnValueRenderer columnData={column} />
                  </div>
                </Fragment>
              ) : null
            )}
      </div>
    </div>
  );
};

export default ItemViewColumnValues;
