import React, { useEffect, useState } from "react";
import AddItemButton from "../addItem/AddItemButton";
import SettingsDrawer from "../Navbar/Settings/SettingsDrawer";
import ItemViewColumnValues from "./ItemViewColumnValues";
import useItemLocations from "../../hooks/api/useItemLocations";
import { getItemNames } from "../../services/monday-service";
import { IconButton, Loader, Skeleton } from "monday-ui-react-core";
import { Retry } from "monday-ui-react-core/icons";
import useUser from "../../hooks/context/userHook";
import AuthorizedUsersButton from "../AuthorizedUsers/AuthorizedUsersButton";

const ItemViewSection = () => {
  const { isLoading, error, tableData, itemIds, mutateItemLocations } =
    useItemLocations();
  const { userId } = useUser();

  const [itemName, setItemName] = useState("");
  const [retryLoading, setRetryLoading] = useState(false);

  useEffect(() => {
    const fetchItemName = async (itemId: number[]) => {
      try {
        const response = await getItemNames(itemId);

        if (response && response.length > 0) {
          setItemName(response[0].name);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    fetchItemName(itemIds);
  }, [itemName, itemIds]);

  const handleRetry = async () => {
    try {
      setRetryLoading(true);
      await mutateItemLocations();
    } catch (error) {
      console.error(error);
    } finally {
      setRetryLoading(false);
    }
  };

  return (
    <>
      <div className="max-w-[640px] mx-auto">
        <div className="flex justify-between mb-[1.875rem]">
          <div className="item-name-wrapper">
            {itemName === "" ? (
              <Skeleton
                type={Skeleton.types.TEXT}
                size={Skeleton.sizes.TEXT.H2}
                width={200}
                height={40}
              />
            ) : (
              <h2 className="!font-poppins font-medium">{itemName}</h2>
            )}
          </div>

          {/* Buttons */}
          <div className="flex gap-3">
            <AddItemButton centered={false} />
            <AuthorizedUsersButton />
            <SettingsDrawer />
          </div>
        </div>
      </div>

      <div className="max-w-[640px] mx-auto">
        {error ? (
          <div className="flex flex-col items-center justify-center gap-2">
            {retryLoading ? (
              <Loader size={Loader.sizes.SMALL} color={Loader.colors.PRIMARY} />
            ) : (
              <>
                <IconButton
                  onClick={handleRetry}
                  className="cursor-pointer"
                  icon={Retry}
                  size={IconButton.sizes.SMALL}
                  kind={IconButton.kinds.PRIMARY}
                />
                <span>An error occurred. Please try again.</span>
              </>
            )}
          </div>
        ) : tableData && tableData.noLinkedItems ? (
          <div className="flex justify-center">
            <h4 className="!font-figtree font-medium">
              This item doesn't live on other boards yet!
            </h4>
          </div>
        ) : (
          <ItemViewColumnValues
            tableData={tableData}
            isLoading={isLoading}
            userId={userId}
          />
        )}
      </div>
    </>
  );
};

export default ItemViewSection;
