//@ts-nocheck
import React, { useState } from "react";
import { Switch } from "antd";
import {
  Modal,
  ModalContent,
  ModalFooterButtons,
  Tooltip,
} from "monday-ui-react-core";
import { Info } from "monday-ui-react-core/icons";
import { SettingsSwitchPropsInterface } from "./types/settings-drawer-interface";

export const SettingSwitch = ({
  label,
  initialValue,
  setNewValue,
  settingKey,
  updateFunction,
  tooltip,
  hasModal = false,
}: SettingsSwitchPropsInterface) => {
  const [showModal, setShowModal] = useState(false);
  const [switchState, setSwitchState] = useState(initialValue);

  const handleSwitch = async (checked: boolean) => {
    const newValue = checked;

    setNewValue((prevSettings) => ({
      ...prevSettings,
      [settingKey]: newValue,
    }));

    setSwitchState(newValue);

    try {
      if (!hasModal || (hasModal && !checked)) {
        await updateFunction(newValue);
      } else if (checked) {
        setShowModal(true);
      }
    } catch (err) {
      console.error(`Failed to update ${label.toLowerCase()}:`, err);

      setValue(!newValue); // Revert change on error
    } finally {
    }
  };

  const confirmModal = async () => {
    try {
      if (hasModal) {
        setNewValue((prevSettings) => ({
          ...prevSettings,
          [settingKey]: true,
        }));
        await updateFunction(true);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setShowModal(false);
    }
  };

  const cancelModal = async () => {
    try {
      await updateFunction(!switchState);
      setShowModal(false);
      setSwitchState(!switchState);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-row gap-1">
        <div className="max-w-60">
          <p className="text-sm">{label}</p>
        </div>
        <div className="flex items-center">
          <Tooltip zIndex={1210} content={tooltip}>
            <Info />
          </Tooltip>
        </div>
      </div>
      <Switch
        checked={switchState}
        onChange={(checked) => {
          handleSwitch(checked);
        }}
      />

      {hasModal && showModal && (
        <Modal
          show={showModal}
          contentSpacing
          id="story-book-modal"
          onClose={() => cancelModal()}
          title="Support moving items to boards"
        >
          <ModalContent>
            <p>
              Enabling this will install the{" "}
              <b>"When an item is moved to any group"</b> webhook. <br />
              This will take effect after 30 minutes.
            </p>
          </ModalContent>

          <ModalFooterButtons
            onPrimaryButtonClick={confirmModal}
            onSecondaryButtonClick={() => cancelModal()}
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
          />
        </Modal>
      )}
    </div>
  );
};
