import React from "react";
import { ColumnValueRendererProps } from "../table.data.interface";

const LinkColumn = ({ columnData }: ColumnValueRendererProps) => {
  if (columnData.value === "Empty Column") {
    return null;
  }
  return (
    <div>
      <a
        href={`${columnData.value}`}
        target="_blank"
        rel="noreferrer"
        className="no-underline cursor-pointer hover:underline"
      >
        {columnData?.value as string}
      </a>
    </div>
  );
};

export default LinkColumn;
