import React from "react";
import { UserStatus } from "monday-ui-react-core/icons";
import { IconButton } from "monday-ui-react-core";
import { useNavigate } from "react-router-dom";

const AuthorizedUsersButton = () => {
  const navigate = useNavigate();
  const handlePageChange = () => {
    navigate("/authorized-users");
  };
  return (
    <IconButton
      onClick={handlePageChange}
      icon={UserStatus}
      size="medium"
      tooltipContent="Authorized Users"
      kind={IconButton.kinds.SECONDARY}
    />
  );
};

export default AuthorizedUsersButton;
