import { Loader } from "monday-ui-react-core";

interface PageLoaderProps {
  screenHeight?: boolean;
  minHeight?: number;
}

const PageLoader = ({ screenHeight = true, minHeight }: PageLoaderProps) => {
  return (
    <div
      className={`flex items-center justify-center w-full ${
        screenHeight
          ? "h-screen"
          : minHeight
          ? `min-h-[${minHeight}px]`
          : "h-auto"
      }`}
    >
      <Loader color={Loader.colors.PRIMARY} size={Loader.sizes.MEDIUM} />
    </div>
  );
};

export default PageLoader;
