import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import { DropdownChevronRight } from "monday-ui-react-core/icons";
import { SettingSwitch } from "./SettingSwitch";
import useIndicator from "../../../hooks/api/Settings/useIndicator";
import useSyncDeletion from "../../../hooks/api/Settings/useSyncDeletion";
import { SettingsDrawerPropsInterface } from "./types/settings-drawer-interface";
import ButtonComponent from "../../Button";
import { useState } from "react";
import { Loader } from "monday-ui-react-core";
import SharedSubitemSettings from "../../ItemsOnBoardComponent/SharedSubitemSettings";
// import ReinstallWebhooks from "../../ReinstallWebhooks/ReinstallWebhooks";
import { Link } from "react-router-dom";
import useMoveItemSetting from "../../../hooks/api/Settings/useMoveItemSetting";
import useUser from "../../../hooks/context/userHook";


const SettingsDrawerList = ({
  switchValues,
  setSwitchValues,
  handleCloseDrawer,
  mutate,
  isLoading,
  itemSettings,
}: SettingsDrawerPropsInterface) => {
  const { updateIndicator } = useIndicator();
  const { updateSyncDeletion } = useSyncDeletion();
  const { updateMoveItemSetting } = useMoveItemSetting();
  const { accountId } = useUser();
  const [retryLoading, setRetryLoading] = useState(false);

  const handleRetry = async () => {
    try {
      setRetryLoading(true);
      await mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setRetryLoading(false);
    }
  };

  return (
    <Box sx={{ width: 350 }}>
      <div onClick={() => handleCloseDrawer(false)} className="p-2 ">
        <div className="cursor-pointer flex items-center justify-between rounded hover:bg-[#6768791a] px-2 py-1">
          <h4 className="font-medium">Settings</h4>
          <DropdownChevronRight />
        </div>
      </div>
      <Divider />

      <div className="flex flex-col gap-3 px-4 py-4">
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Loader color={Loader.colors.PRIMARY} size={Loader.sizes.MEDIUM} />
          </div>
        ) : !switchValues ? (
          <>
            {retryLoading ? (
              <>
                <div className="flex items-center justify-center">
                  <Loader
                    color={Loader.colors.PRIMARY}
                    size={Loader.sizes.MEDIUM}
                  />
                </div>
              </>
            ) : (
              <>
                <p>
                  Could not retrieve your account settings, please try again.
                </p>
                <ButtonComponent text={"Retry"} onClick={handleRetry} />
              </>
            )}
          </>
        ) : (
          <>
            <div>
              <h4 className="!font-figtree font-medium !mb-2">
                General Settings
              </h4>
              <div className="flex flex-col gap-1">
                <SettingSwitch
                  label="Visual indicator"
                  initialValue={switchValues?.visual_indicator_setting}
                  setNewValue={setSwitchValues}
                  settingKey="visual_indicator_setting"
                  updateFunction={updateIndicator}
                  tooltip="Adds an emoji to the name of all connected items"
                />
                <SettingSwitch
                  label="Sync item deletion across boards"
                  initialValue={switchValues?.sync_deletion_setting}
                  setNewValue={setSwitchValues}
                  settingKey="sync_deletion_setting"
                  updateFunction={updateSyncDeletion}
                  tooltip="When an item is deleted on monday all items connected to it will be deleted"
                />

                <SettingSwitch
                  label="Support moving item to boards"
                  initialValue={switchValues?.move_item_setting}
                  setNewValue={setSwitchValues}
                  settingKey="move_item_setting"
                  updateFunction={updateMoveItemSetting}
                  tooltip="When this feature is enabled, items will keep their connection even when moving to other boards. Note: this feature will install a ‘When an item is moved to any group’ webhook on all boards, including other workspaces. This will take effect after 30 minutes."
                  hasModal={true}
                />

                {/* <ReinstallWebhooks /> */}
              </div>
            </div>

            <div>
              <h4 className="!font-figtree font-medium !mb-2">Item Settings</h4>
              <SharedSubitemSettings
                isLoading={itemSettings.itemsSettingsLoading}
                error={itemSettings.error}
                subitemSettings={itemSettings.subitemSettings}
                updateSettings={itemSettings.updateSettings}
                userId={itemSettings.userId}
                itemIds={itemSettings.itemIds}
              />
            </div>
          </>
        )}
        <div className="flex justify-center">
          <Link to="/detailed-usage" className="detailed-usage-link">
            See Detailed Usage
          </Link>
        </div>
      </div>
    </Box>
  );
};

export default SettingsDrawerList;
