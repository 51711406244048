import React, { useState } from "react";
import { Space } from "antd";
import { Info, MoveArrowLeft } from "monday-ui-react-core/icons";
import { Link } from "react-router-dom";
import UsersGrid from "../components/AuthorizedUsers/UsersGrid";
import { Search, Tipseen, TipseenContent } from "monday-ui-react-core";
import { MondayApiQueryInstance } from "../services/monday/monday-service-ts";

const AuthorizedUsersPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [closeTipseen, setCloseTipseen] = useState(true);

  const handleSearch = (event: string) => {
    setSearchTerm(event);
  };

  const handleReadMore = async () => {
    try {
      await MondayApiQueryInstance.openLinkInTab(
        "https://sameitem.pioneera.io/en/article/how-updates-between-boards-work-in-same-item-multiple-boards-1pwmal1/"
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="max-w-[640px] h-screen px-5 pt-8 pb-4 mx-auto">
      <Space direction="vertical" className="flex items-start w-full gap-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-1">
            <Link to="/">
              <MoveArrowLeft />
            </Link>
            <h2 className="!font-poppins font-medium">Authorized Users</h2>

            <div
              className="flex items-center"
              onMouseEnter={() => {
                setCloseTipseen(false);
              }}
            >
              <Tipseen
                tooltipArrowClassName={closeTipseen ? "hidden" : ""}
                onClose={() => {
                  setCloseTipseen(true);
                }}
                className={closeTipseen ? "hidden" : ""}
                content={
                  <TipseenContent
                    title="Why am I seeing this?"
                    submitButtonText="Read More"
                    onSubmit={handleReadMore}
                    hideDismiss={false}
                    onDismiss={() => setCloseTipseen(true)}
                  >
                    Each user in your monday account needs to authorize the app
                    so their updates will sync to other boards. Use the mail
                    icon to notify them about it!
                  </TipseenContent>
                }
                modifiers={[
                  {
                    name: "preventOverflow",
                    options: {
                      mainAxis: false,
                    },
                  },
                  {
                    name: "flip",
                    options: {
                      fallbackPlacements: [],
                    },
                  },
                ]}
                color={Tipseen.colors.INVERTED}
              >
                <Info size={24} className="cursor-pointer" />
              </Tipseen>
            </div>
          </div>
          <div className="min-w-60">
            <Search
              size="small"
              placeholder="Search Team Member"
              value={searchTerm}
              onChange={(event) => {
                handleSearch(event);
              }} // Simplified onChange
            />
          </div>
        </div>
        <div>
          <UsersGrid searchTerm={searchTerm} />
        </div>
      </Space>
    </div>
  );
};

export default AuthorizedUsersPage;
