import React from "react";
import useTokenHook from "../../hooks/context/useTokenHook";
import useUser from "../../hooks/context/userHook";
import mondaySdk from "monday-sdk-js";
import useBoardAndItems from "../../hooks/context/useBoardAndItems";
import axios from "axios";
import { Group } from "monday-ui-react-core/icons";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { BoardGroups } from "./BoardsList";
import Spinner from "../Spinner";
import { useList } from "../../hooks/componentHooks/listHooks";

const monday = mondaySdk();
monday.setApiVersion("2023-10");

const uri = process.env.REACT_APP_URI;

interface GroupListProps {
  processUpdates: boolean;
  processSubitems: boolean;
  boardId: string;
  boardGroups: BoardGroups;
  boardSynced: boolean;
  batchSize?: number;
  disableAll?: boolean;
}

export type GroupType = {
  id: string;
  title: string;
  deleted: boolean;
  archived: boolean;
  color: string;
};

const GroupsList = ({
  processUpdates,
  processSubitems,
  boardId,
  boardGroups,
  boardSynced,
  batchSize,
  disableAll,
}: GroupListProps) => {
  const { userId, accountId } = useUser();
  const { currentBoard, itemIds } = useBoardAndItems();
  const { token } = useTokenHook();

  const {
    isLoading: itemsLoader,
    setItemLoading,
    setItemDisabled,
    isDisabled: itemsDisabled,
    isDisableAll,
    disableOthers,
  } = useList();

  const addNewItem = async (toSyncGroupId: string, toSyncBoardId: string) => {
    try {
      setItemLoading(`${toSyncGroupId}-${toSyncBoardId}`);
      setItemDisabled(`${toSyncGroupId}-${toSyncBoardId}`);

      if (disableAll) {
        disableOthers(true);
      }
      monday.execute("notice", {
        message:
          "Your item is being added to the other board. You can continue using monday meanwhile!",
        type: "info", // or "error" (red), or "info" (blue)
        timeout: 10000,
      });

      // TODO: Must be removed and checked on the actual endpoint
      // const statusResponse = await axios.post(
      //   `${uri}/check-paywall-status`,
      //   { itemid: itemIds, targetBoardId: toSyncBoardId },
      //   {
      //     headers: {
      //       userid: userId,
      //     },
      //   }
      // );

      // if (!statusResponse.data.data.status.status) {
      //   monday.execute("notice", {
      //     message: statusResponse.data.data.status.message,
      //     type: "error",
      //     timeout: 10000,
      //   });
      //   return;
      // }
      //end of paywall block

      //add-subItem endpoint
      await axios.post(
        `${uri}/simb/add-item-and-sync`,
        {
          payload: {
            accountId: accountId,
            itemIdArray: itemIds,
            originBoardId: currentBoard,
            target: {
              boardId: toSyncBoardId,
              groupId: toSyncGroupId,
            },
            processSubItem: processSubitems,
            processUpdates: processUpdates,
            batchSize: batchSize,
          },
        },
        {
          headers: {
            userid: userId,
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error: any) {
      console.error("error => ", error);
    }
  };

  return (
    <List component="div" disablePadding>
      {boardGroups[boardId as string] ? (
        boardGroups[boardId as string].map((group: GroupType) => (
          <ListItemButton
            onClick={(e) => {
              e.stopPropagation();
              addNewItem(group.id, boardId);
            }}
            key={group.id}
            sx={{ pl: 4 }}
            disabled={
              boardSynced ||
              isDisableAll ||
              itemsDisabled.some((item: string) => item.endsWith(`-${boardId}`))
            }
          >
            <ListItemIcon
              sx={{
                minWidth: "unset",
                color: `${group.color}`,
              }}
              className="!w-auto mr-1"
            >
              <Group />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                fontSize: "0.875rem",
                fontFamily: "Poppins",
                color: `${group.color}`,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              primary={group.title}
            />

            {itemsLoader.includes(`${group.id}-${boardId}`) && (
              <Spinner size={14} />
            )}
          </ListItemButton>
        ))
      ) : (
        <Spinner />
      )}
    </List>
  );
};

export default GroupsList;
