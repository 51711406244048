import React, { useEffect, useState } from "react";
import { ColumnValueRendererProps } from "../table.data.interface";
import { Tooltip } from "monday-ui-react-core";
import { MondayApiQueryInstance } from "../../../services/monday/monday-service-ts";

const PersonColumn = ({ columnData, itemIds }: ColumnValueRendererProps) => {
  const [personsAndTeams, setPersonsAndTeams] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const fetchPersonData = async (columnId: string, itemIds: number[]) => {
    try {
      setError(false);

      // Fetch data for teams and persons
      const response = await MondayApiQueryInstance.getPersonColumnData(
        itemIds,
        columnId
      );

      const text = response?.items[0]?.column_values[0]?.text;

      const personsAndTeamsArray =
        response?.items[0]?.column_values[0]?.persons_and_teams;

      const names = text.split(",").map((name: string) => name.trim());

      personsAndTeamsArray.forEach((item: any, index: number) => {
        if (names[index]) {
          item.name = names[index];
        }
      });

      const persons = personsAndTeamsArray.filter(
        (item: any) => item.kind === "person"
      );

      const teams = personsAndTeamsArray.filter(
        (item: any) => item.kind === "team"
      );

      const personsId = persons.map((person: any) => person.id);

      const personData = await MondayApiQueryInstance.getUsersByUserId(
        personsId
      );

      const combinedData = [...personData, ...teams];

      setPersonsAndTeams(combinedData);
    } catch (error: any) {
      setError(true);
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (columnData?.value !== "Empty Column" && columnData?.value) {
      fetchPersonData(columnData?.columnId, itemIds as number[]);
    }
  }, [columnData?.columnId]);

  if (error) {
    return null;
  }

  if (columnData.value === "Empty Column" || !columnData.value || error) {
    return null;
  }

  const arrayOfPersonsAndTeams = personsAndTeams;
  const displayedItems = arrayOfPersonsAndTeams.slice(0, 3);
  const remainingPersonandTeams = arrayOfPersonsAndTeams.slice(3);

  return (
    <div className="flex font-semibold">
      {displayedItems.map((personAndTeam: any, index) => (
        <Tooltip content={personAndTeam.name} key={index}>
          {personAndTeam.photo_thumb ? (
            <img
              alt={`${personAndTeam.name}`}
              src={`${personAndTeam.photo_thumb}`}
              width={30}
              height={30}
              className="-mx-1 border-solid border-2 bg border-[#f5f6f8] cursor-pointer rounded-full"
            />
          ) : (
            <div
              className={`-mx-1 w-[30px] h-[30px] rounded-full flex items-center justify-center border-solid border-2 bg border-[#f5f6f8] cursor-pointer overflow-hidden ${
                personAndTeam.photo_thumb ? "" : "bg-primary-color text-white"
              }`}
            >
              <span className="!font-poppins text-xs">
                {personAndTeam?.name?.charAt(0).toUpperCase()}
              </span>
            </div>
          )}
        </Tooltip>
      ))}
      {arrayOfPersonsAndTeams.length > 3 && (
        <Tooltip
          content={
            <div className="flex flex-col">
              {remainingPersonandTeams.map((personAndTeam: any, index) => (
                <div key={index} className="text-sm text-white">
                  {personAndTeam?.name}
                </div>
              ))}
            </div>
          }
        >
          <div className="-mx-1 px-2 rounded-full text-white bg-[#DCDFEC] flex items-center justify-center border-solid border bg border-[#f5f6f8] cursor-pointer">
            <span className="!font-poppins text-xs text-[#676879]">
              +{remainingPersonandTeams.length}
            </span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default PersonColumn;
