import simbApi from ".";
import {
  GET_SETTINGS,
  MOVE_ITEM_SETTING_ENDPOINT,
  SYNC_DELETION_SETTINGS,
  VISUAL_INDICATOR_SETTINGS,
} from "../../data/constants/endpoints";

export const getSettings = async (access_token) => {
  try {
    const response = await simbApi.get(GET_SETTINGS, {
      headers: { Authorization: `Bearer ${access_token}` },
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const syncDeletion = async (access_token, accountId, setting) => {
  try {
    const response = await simbApi.post(
      SYNC_DELETION_SETTINGS,
      {
        accountId: accountId,
        syncDeletionSettingData: setting,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const visualIndicator = async (access_token, accountId, setting) => {
  try {
    const response = await simbApi.post(
      VISUAL_INDICATOR_SETTINGS,
      {
        accountId: accountId,
        visualIndicatorSettingData: setting,
      },
      { headers: { Authorization: `Bearer ${access_token}` } }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const moveItemSetting = async (access_token, accountId, setting) => {
  try {
    const response = await simbApi.post(
      MOVE_ITEM_SETTING_ENDPOINT,
      {
        accountId: accountId,
        moveItemSettingData: setting,
      },
      {
        headers: { Authorization: `Bearer ${access_token}` },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
