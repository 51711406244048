import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "antd";
import mondaySdk from "monday-sdk-js";
import axios from "axios";
import {
  Modal,
  ModalContent,
  ModalFooterButtons,
  Search,
} from "monday-ui-react-core";
import Spinner from "../Spinner";
import WorkspacesAndBoardsList from "./WorkspacesAndBoardsList";
import { Checkbox } from "monday-ui-react-core";
import useUser from "../../hooks/context/userHook";
import useBoardAndItems from "../../hooks/context/useBoardAndItems";
import { useList } from "../../hooks/componentHooks/listHooks";
import useTokenHook from "../../hooks/context/useTokenHook";
import { useCheckSubitems } from "../../hooks/api/useCheckSubitems";
import { useCheckUpdates } from "../../hooks/api/useCheckUpdates";

const monday = mondaySdk();
monday.setApiVersion("2023-10");

const uri = process.env.REACT_APP_URI;

const AddItem = ({ calcHeight, batchSize = 50, disableAll, moreThan20 }) => {
  const { userId, accountId } = useUser();
  const { token } = useTokenHook();

  const {
    isLoading: itemsLoader,
    setItemLoading,
    setItemDisabled,
    isDisabled: itemsDisabled,
    disableOthers,
    isDisableAll,
  } = useList();

  const { currentBoard, itemIds } = useBoardAndItems();
  const [newItemLoadingState] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [previousHeight, setPreviousHeight] = useState(0);
  const smallAccountContainer = useRef(null);
  const { processSubitems, setProcessSubitems } = useCheckSubitems(itemIds);

  const { updatesExist, processUpdates, setProcessUpdates } =
    useCheckUpdates(itemIds);

  useEffect(() => {
    calcHeight(smallAccountContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (moreThan20 && processSubitems) {
      setModalOpen(true);
    }
  }, [moreThan20, processSubitems]);

  useEffect(() => {
    const checkHeightChange = () => {
      if (smallAccountContainer.current) {
        const currentHeight = smallAccountContainer.current.offsetHeight;

        if (smallAccountContainer !== currentHeight) {
          // Height has changed

          setPreviousHeight(currentHeight);

          // Call calcHeight if needed
          calcHeight(smallAccountContainer);
        }
      }
    };

    // Initial check on mount
    checkHeightChange();

    // Set up a MutationObserver to listen for DOM changes
    const observer = new MutationObserver(checkHeightChange);
    if (smallAccountContainer.current) {
      observer.observe(smallAccountContainer.current, {
        childList: true,
        subtree: true,
        attributes: true,
        characterData: true,
      });
    }

    // Clean up on unmount
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousHeight]);

  //this will create a new update and
  const addNewItem = async (toSyncGroupId, toSyncBoardId) => {
    try {
      //add the loading spinner
      if (disableAll) {
        disableOthers(true);
      }

      setItemLoading(`${toSyncGroupId}-${toSyncBoardId}`);
      setItemDisabled(`${toSyncGroupId}-${toSyncBoardId}`);

      monday.execute("notice", {
        message:
          "Your item is being added to the other board. You can continue using monday meanwhile!",
        type: "info", // or "error" (red), or "info" (blue)
        timeout: 10000,
      });

      // TODO: Must be removed and checked on the actual endpoint
      // const statusResponse = await axios.post(
      //   `${uri}/check-paywall-status`,
      //   { itemid: itemIds, targetBoardId: toSyncBoardId },
      //   {
      //     headers: {
      //       userid: userId,
      //     },
      //   }
      // );

      // if (!statusResponse.data.data.status.status) {
      //   monday.execute("notice", {
      //     message: statusResponse.data.data.status.message,
      //     type: "error",
      //     timeout: 10000,
      //   });
      //   return;
      // }
      //end of paywall block

      //add-subItem endpoint
      await axios.post(
        `${uri}/simb/add-item-and-sync`,
        {
          payload: {
            accountId: accountId,
            itemIdArray: itemIds,
            originBoardId: currentBoard,
            target: {
              boardId: toSyncBoardId,
              groupId: toSyncGroupId,
            },
            processSubItem: processSubitems,
            processUpdates: processUpdates,
            batchSize: batchSize,
          },
        },
        {
          headers: {
            userid: userId,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // if (!addItem.data.error) {
      //   monday.execute("notice", {
      //     message: `Syncing Processing`,
      //     type: "info", // or "error" (red), or "info" (blue)
      //     timeout: 10000,
      //   });

      // }
    } catch (error) {
      console.log("error => ", error.message);

      // let errorMessage = `An error has occurred. Item did not sync successfully.`;

      // if (error?.response?.data?.error) {
      //   errorMessage = error?.response?.data?.error;

      //   if (errorMessage === "User unauthorized to perform action") {
      //     errorMessage =
      //       "You do not have permissions to create or edit in this board.";
      //   }
      // }

      // removeItemDisabled(`${toSyncGroupId}-${toSyncBoardId}`);

      // monday.execute("notice", {
      //   message: errorMessage,
      //   type: "error", // or "error" (red), or "info" (blue)
      //   timeout: 10000,
      // });
    }
  };

  const handleSearch = (value) => {
    setSearchText(value);
    // Perform search on the initial workspaces
  };

  // Function to close the modal
  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="relative" ref={smallAccountContainer}>
      <Row className="pt-[15px] pb-[12px] bg-[#FFFFFF] sticky top-0 z-[1] border-b border-[#C3C6D4] overflow-x-hidden">
        {/* Dropdown Search */}
        <>
          <Search
            placeholder="Search Workspaces and Boards"
            onChange={(e) => {
              handleSearch(e);
            }}
            size={Search.sizes.SMALL}
            style={{
              width: "100%",
            }}
            value={searchText}
          />

          <div className="flex flex-col gap-2 mt-2">
            <Checkbox
              label="Include Subitems"
              checked={processSubitems}
              onChange={(e) => {
                setProcessSubitems(e.target.checked);
              }}
            />
            {updatesExist && (
              <Checkbox
                label="Include Updates"
                checked={processUpdates}
                onChange={(e) => {
                  setProcessUpdates(e.target.checked);
                }}
              />
            )}
          </div>
        </>
      </Row>

      <Row
        style={{
          backgroundColor: "#FFFFFF",
          width: "100%",
        }}
        className="rounded-b add-item-row"
      >
        <Col span={24} className="pb-0 mt-2 mb-0">
          {!newItemLoadingState ? (
            <>
              <WorkspacesAndBoardsList
                searchText={searchText}
                addItem={addNewItem}
                itemsLoader={itemsLoader}
                itemsDisabled={itemsDisabled}
                isDisableAll={isDisableAll}
              />
            </>
          ) : (
            <>
              <div className="flex flex-col items-center gap-2">
                <Spinner className="mb-2" />
                <span>
                  Adding your item to the new board. Please do not close this
                  window and the item view.
                </span>
              </div>
            </>
          )}
        </Col>
      </Row>

      {modalOpen && (
        <Modal
          contentSpacing
          id="story-book-modal"
          onClose={closeModal} // Close the modal when the "X" button is clicked
          title="Limit reached."
          show={modalOpen}
        >
          <ModalContent>
            <p>
              One or more of your selected items have more than 20 subitems.
              Please deselect this item or do not include subitems when adding
              to another board. Otherwise, some items and subitems will not be
              processed.
            </p>
          </ModalContent>
          <ModalFooterButtons
            onPrimaryButtonClick={() => {
              // Handle primary action

              closeModal(); // Close modal on confirm
            }}
            primaryButtonText="Close"
          />
        </Modal>
      )}
    </div>
  );
};

export default AddItem;
