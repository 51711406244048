import React from "react";
import { IconButton, Label } from "monday-ui-react-core";
import { Email, Retry } from "monday-ui-react-core/icons";
import useFetchUserStatus from "../../hooks/api/useFetchUserStatus";
import PageLoader from "../PageLoader";
import { Pagination } from "@mui/material";
import useBoardAndItems from "../../hooks/context/useBoardAndItems";
import { MondayApiQueryInstance } from "../../services/monday/monday-service-ts";
import mondaySdk from "monday-sdk-js";

const monday = mondaySdk();

const UsersGrid = ({ searchTerm }: { searchTerm: string }) => {
  const {
    users,
    isLoading,
    error,
    fetchPage,
    page,
    hasMore,
    totalPages,
    fetchUsers,
  } = useFetchUserStatus(10, searchTerm);
  const { currentBoard } = useBoardAndItems();

  const handleSendAuthNotification = async (userId: number) => {
    try {
      await MondayApiQueryInstance.sendNotification(
        currentBoard,
        userId,
        "Hi there! Please authorize the <a rel='norefferer' target='_blank' href='https://auth.monday.com/oauth2/authorize?client_id=9d9d91f40e511e3954b7d1df7184115e'>Same Item Multiple Boards</a> app to keep your updates synced across other boards. It only takes a moment—thank you!"
      );
      monday.execute("notice", {
        message: `Authorization notification sent! `,
        type: "success", // or "error" (red), or "info" (blue)
        timeout: 10000,
      });
    } catch (error) {
      monday.execute("notice", {
        message: `Failed to send notification.`,
        type: "error", // or "error" (red), or "info" (blue)
        timeout: 10000,
      });
      console.error(error);
      throw error;
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    // Only call fetchPage if the page is within the valid range
    if (newPage <= totalPages || hasMore) {
      fetchPage(newPage); // Fetch new page based on page change
    }
  };

  const handleRetry = () => {
    fetchUsers(1, "");
  };

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center gap-2 mx-auto">
        <IconButton
          onClick={handleRetry}
          className="cursor-pointer"
          icon={Retry}
          size={IconButton.sizes.SMALL}
          kind={IconButton.kinds.PRIMARY}
        />
        <span>An error occurred. Please try again.</span>
      </div>
    );
  }

  return (
    <div>
      {isLoading ? (
        <PageLoader screenHeight={false} minHeight={462} />
      ) : (
        <div className="grid grid-cols-1">
          {users.length !== 0 &&
            users.map((user: any) => (
              <div
                key={user.id}
                className="grid grid-cols-[2fr_1fr] p-2 border border-solid border-t-[#E7E9EF] border-b-0 border-r-0 border-l-0 box-border"
              >
                {/* user column */}
                <div className="flex items-center gap-2">
                  <div
                    className={`-mx-1 w-[30px] h-[30px] rounded-full flex items-center justify-center border-solid border bg border-[#f5f6f8] cursor-pointer overflow-hidden ${
                      user.photo_thumb ? "" : "bg-primary-color"
                    }`}
                  >
                    {user.photo_thumb ? (
                      <img
                        alt={`${user.name}`}
                        src={`${user.photo_thumb}`}
                        width={30}
                        height={30}
                      />
                    ) : (
                      <span className="!font-poppins text-xs">{user.name}</span>
                    )}
                  </div>
                  <p className="text-sm">{user?.name}</p>
                </div>

                {/* Action/Status column */}

                <div className="flex items-center justify-end gap-3">
                  <Label
                    text={user.status ? "Authorized" : "Unauthorized"}
                    color={
                      user.status ? Label.colors.POSITIVE : Label.colors.DARK
                    }
                  />
                  <IconButton
                    tooltipContent="Send Authorization Notification"
                    icon={Email}
                    size="small"
                    disabled={user.status ? true : false}
                    onClick={() => {
                      handleSendAuthNotification(user.id);
                    }}
                  />
                </div>
              </div>
            ))}
          <div className="flex justify-center mb-4">
            <Pagination
              page={page}
              onChange={handlePageChange}
              count={totalPages} // Dynamically adjust total pages
              color="primary"
              shape="rounded"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default UsersGrid;
